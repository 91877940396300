<template>
  <div class="jexy-checkboxes">
    <v-checkbox
      v-for="option in control.items"
      :key="option"
      :label="option"
      :value="option"
      v-model="control.model[control.name]"
      v-bind="control"
      class="jexy-checkbox"
    />
  </div>
</template>

<script>

// Mixin
import Control from './Mixin'

export default {
  name: 'Checkboxes',
  mixins: [ Control ]
}
</script>
